// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).ready(function(){
    $("section.page_body.disabled_overview input").prop('disabled', true);

    // Force-show text fields if radio btn is checked
    // NOTE: This block of javascript should be limited to alat_dva via #formId selector
    // Example: $("#formId input[type=radio]:checked").each ... etc
    $("input[type=radio]:checked").each(function() {
      var value = parseInt($(this).val());
      if(value<4) {
        $(this).parent().parent().parent().next().css("display","block");
      }
    });

    mybutton = document.getElementById("back_to_top");

    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
      if (mybutton != null){
        if (document.body.scrollTop > 550 || document.documentElement.scrollTop > 550) {
          mybutton.style.display = "block";
        } else {
          mybutton.style.display = "none";
        }
      }
    }
});
