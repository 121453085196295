import React, {useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';

const Tinymce = props => {

  const [content, setContent] = useState(props.content)

  let toolbar_buttons = 'undo redo | cut copy paste | bold italic | bullist numlist | link | code help'

  if(props.extended)
    toolbar_buttons = 'undo redo | cut copy paste | styleselect bold italic | bullist numlist | link  image media | code help'

  if(props.minimal)
    toolbar_buttons = ''

  return <div style={{width: '79%', marginLeft: '20%'}}>
    <input type="hidden" name={props.name} value={content} />
    <Editor
     apiKey="akkadfn2r13cdt4d89n2v9o78m2wgdl383vkon4h8v2wkfvg"
     initialValue={props.content}
     init={{
       height: props.height,
       menubar: false,
       inline: props.minimal,
       formats: {
          // smallCaps: { inline: 'span', classes: 'smallCaps' },
          // imageCaption: { inline: 'span', classes: 'image_caption' },
          // noIndent: { block: 'p', classes: 'noIndent' },
          // question: { block: 'p', classes: 'question' },
          // answer: { block: 'p', classes: 'answer' },
          subtitle: { block: 'h2' }
        },
        style_formats: [
          // { title: 'smallCaps', format: 'smallCaps' },
          // { title: 'Potpis slike', format: 'imageCaption' },
          // { title: 'Bez uvlake', format: 'noIndent' },
          // { title: 'Pitanje', format: 'question' },
          // { title: 'Odgovor', format: 'answer' },
          { title: 'Podnaslov', format: 'subtitle' },
      ],
       plugins: [
         'image link code paste lists wordcount media help visualblocks quickbars anchor', //advlist

       ],
       // external_plugins: {
       //   'abbr': '/tinymce_external_plugins/abbr/plugin.js'
       // },
       // content_css: '/tinymce_custom_v2.css',
       quickbars_selection_toolbar: props.minimal ? 'bold italic styleselect' : 'bold italic styleselect | quicklink',
       quickbars_insert_toolbar: false,
       quickbars_image_toolbar: false,
       toolbar: toolbar_buttons
     }}
     onEditorChange={(editorContent) => setContent(editorContent)}
   />
   </div>
}
export default Tinymce
