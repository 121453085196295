require('selectize/');

$(document).ready(function() {

  $(".radio:nth-of-type(1) label, .radio:nth-of-type(2) label, .radio:nth-of-type(3) label").click(function () {
    $(this).parent().parent().next(".subordinate").hide().slideDown(300);
  });
  $(".radio:nth-of-type(4) label").click(function () {
    $(this).parent().parent().next(".subordinate").slideUp(200);
  });

  $('.selectize').selectize({
    // create: function(input) {
    //   return {
    //       value: input,
    //       text: input
    //   }
    // }
  });

});
