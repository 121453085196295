// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.


$(document).ready(function(){
  $("a").each(function(){
    if($(this).hasClass("disabled")){
      $(this).removeAttr("href");
    }
  });

  $(".graph_container .content").each(function(){

    var maxHeight = 0;
    var labelPosition = 0;
    $(".bar",$(this)).each(function(){
      if(Math.abs(parseFloat($(this).attr("value"))) > maxHeight){
        maxHeight = parseFloat($(this).attr("value"));
        maxHeight = Math.abs(maxHeight);
      }
    })
    var nItems = $(".service_item" , $(this)).length;
    if(nItems < 10 && nItems > 1 ){
      $(this).css({"width" : nItems *100})
    }else if(nItems == 1){
      $(this).css({"width" : nItems *60})
    }else if (nItems => 10){
      $(this).css({"width" : "100%"})
    }
    else{}
    $(".bar",$(this)).each(function(){
      var height = Math.round(parseFloat($(this).attr("value"))*200/ maxHeight) ;
      var altH = 0;
      if (height > 0 ) {
        $(this).parent().parent().addClass('positive');
        altH = 0 ;
      }else if(height < 0){
        $(this).parent().parent().addClass('negative');
        height = height*-1 ;
        altH = height ;
      }else{
        $(this).parent().parent().addClass('zero');
      }
      $(this).height(height);
      if( altH+45 > labelPosition+31 || altH +45 < labelPosition-31 ){
        labelPosition = altH+30;
        $("span.line",$(this).parent()).height(30);
      }
      else if( altH +130 > labelPosition+10 || altH +130 < labelPosition-10 ){
        labelPosition = altH+130;
        $("span.line",$(this).parent()).height(130);
      }
      else {
        labelPosition = altH+230;
        $("span.line",$(this).parent()).height(230);
      }
    });
  });

});
